import React from 'react'

import Layout from '../components/Layout'

const Missing = () => {
    return (
        <>
            <Layout>
                <h1>404</h1>
            </Layout>
        </>
    )
}

export default Missing